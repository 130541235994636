<template>
    <section class="cotizacion-ver container-fluid overflow-auto custom-scroll">
        <navbar-admin titulo="Cotizaciones > Cotización 0001"  />
        <!-- info cotizacion -->
        <div class="my-4">
            <div class="bg-f5 border br-8 pr-4 br-50-6">
                <div class="row mx-0 contenedor-info d-middle">
                    <img :src="detalleInfo.imagen | helper-storage" alt="" class="img-logo-op wh-126 rounded-circle position-relative">
                    <div class="col py-2">
                        <div class="d-middle justify-content-between">
                            <p class="f-18 f-700">Cotización {{ detalleInfo.referencia }}</p>
                            <div class="d-flex justify-content-end">
                                <p class="f-18 f-600 my-auto mr-3">
                                    Total {{ formatoMoneda(detalleInfo.total) }}
                                </p>
                                <progress-ring class="mx-3" size="small" :percentage="Number(detalleInfo.porcentaje_cotizados)" wh="60" />
                            </div>
                        </div>
                        <p class="f-14 f-500 mb-3">{{ detalleInfo.empresa }}</p>
                        <div class="row mr-4">
                            <div class="col-12 col-lg-6">
                                <div class="row">
                                    <div class="col-auto">
                                        <div class="d-middle">
                                            <el-tooltip content="Materiales cotizados" placement="bottom" effect="light" visible-arrow>
                                                <i class="icon-format-list-numbered" />
                                            </el-tooltip>
                                            <p class="f-500 f-14">{{ detalleInfo.materiales_cotizados }}</p>
                                        </div>
                                    </div>
                                    <div class="col-auto">
                                        <div class="d-middle">
                                            <i :class="detalleInfo.estado == 'Habilitado' ? 'icon-file-document-edit-outline text-naranja' : 'icon-progress-pencil'" />
                                            <p class="f-500 f-14">{{ detalleInfo.estado }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6 ">
                                <p class="f-600 f-14 text-right">
                                    Fecha de solicitud:
                                    <span class="f-300 ml-2">{{ detalleInfo.fecha_solicitud | helper-fecha('DD MMM. YYYY') }}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- titulo + filtro -->
        <titulo-divisor titulo="Materiales cotizados">
            <div class="d-middle gap-1">
                <el-input v-model="filter.nombre" @keydown.native.enter="listMateriales" placeholder="Buscar cotizaciones" size="small" />
                <filter-indicator @openModalFilter="abrirModalFiltros" @clearFilter="limpiarFiltro" :count="count" />
            </div>
        </titulo-divisor>
        <!-- acciones -->
        <div class="d-flex justify-content-end my-4">
            <button class="btn f-12 btn-general"
                :class="permitAction('cotizaciones.vigentes.enviar') ? 'cr-pointer' : 'cr-not-allowed'" 
                @click="functionPermitAction('cotizaciones.vigentes.enviar', openEnviarCotizacion)"
            >Enviar cotización</button>
        </div>
        <!-- tabla -->
        <div class="w-100">
            <el-table ref="multipleTable" :data="materiales" style="width: 100%" class="f-12">
                <el-table-column label="Nombre" prop="nombre" width="250" show-overflow-tooltip />
                <el-table-column label="U.M" prop="unidad_medida" align="center" />
                <el-table-column label="Cantidad" prop="cantidad" align="center" />
                <el-table-column label="Moneda" align="center">
                    <template slot-scope="scope">
                        <el-select v-model="scope.row.id_moneda" placeholder="Seleccionar" size="small" class="w-100">
                            <el-option v-for="item in select_monedas" :key="item.id" :label="item.nombre" :value="item.id" />
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column label="Valor unitario" prop="valor_unidad" align="center">
                    <template slot-scope="scope">
                        <CustomCurrencyInput 
                            v-model="scope.row.valor_unidad"
                            :options="{ currency: 'USD' }"
                            @blur="editarMaterial($event, scope.$index)"
                        />
                    </template>
                </el-table-column>
                <el-table-column label="Valor Parcial" align="center">
                    <template slot-scope="scope">
                        <p>{{ formatoMoneda(scope.row.valor_unidad*scope.row.cantidad) }}</p>
                    </template>
                </el-table-column>
                <el-table-column label="Garantía" prop="garantia" align="center">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.garantia" @change="editarMaterial($event, scope.$index)" class="w-100 input-center" size="small" />
                    </template>
                </el-table-column>
                <el-table-column label="Días de entrega" prop="dias_entrega" align="center">
                    <template slot-scope="scope">
                        <el-input-number v-model="scope.row.dias_entrega" @change="editarMaterial($event, scope.$index)" :controls="false" class="w-100 input-center" size="small" />
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- partials  -->
        <modal-lateral ref="abrirModalFiltros" titulo="Filtro">
            <div class="row mx-0 justify-content-center my-2">
                <div class="col-11 my-4">
                    <p class="input-label-top">Nombre</p>
                    <el-input v-model="filter.nombre" type="text" size="small"/>
                </div>
                <div class="col-11 my-4">
                    <p class="input-label-top">Unidad de medida</p>
                    <el-select v-model="filter.id_unidad_medida" clearable filterable placeholder="Seleccionar unidad de medida" size="small" class="w-100">
                        <el-option v-for="unit in select_measure_units" :key="unit.id" :label="`${unit.unidad_medida} (${unit.sigla})`" :value="unit.id" />
                    </el-select>
                </div>
                <div class="col-11 my-3">
                    <p class="input-label-top">Rango de valor</p>
                    <el-slider v-model="rangueValue" range :min="rangos.cantidad_min" :max="rangos.cantidad_max" :disabled="disabledSlider" />
                </div>
            </div>
            <div class="botones">
                <button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" @click="limpiarFiltro"> Limpiar </button>
                <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="listMateriales"> Filtrar </button>
            </div>
        </modal-lateral>
        <modal-enviar-cotizacion ref="enviarCotizacion" @enviarCotizacion="enviarCotizacion" />
    </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
    components:{
        modalEnviarCotizacion: () => import('./partials/modalEnviarCotizacion.vue')
    },
    data(){
        return{
            idCotizacion: this.$route.params.id_cotizacion,
        }
    },
    computed: {
        ...mapGetters({
            select_measure_units: 'selects/selects/select_measure_units',
            detalleInfo: 'cotizacion/detalleCotizacion/detalleInfo',
            materiales: 'cotizacion/detalleCotizacion/materiales',
            filtros: 'cotizacion/detalleCotizacion/filtros',
            select_monedas: 'selects/selects/select_monedas',
            rangos: 'cotizacion/detalleCotizacion/rangos',
        }),
        filter: {
            get(){ return this.filtros },
            set(val){ this.set_filtros(val) }
        },
        rangueValue:{
            get(){ return [this.filter.cantidad_min, this.filter.cantidad_max] },
            set(val){ this.filter.cantidad_min = val[0], this.filter.cantidad_max = val[1] }
        },
        count(){
            return this.filter.count()
        },
        disabledSlider(){
            return this.rangos.cantidad_min == this.rangos.cantidad_max ? true : false
        }
    },
    async created () {
        await this.Action_get_detalle_info(this.idCotizacion);
        this.$store.dispatch('navigation/breadcumbs/getBreadcumbs', ['cotizaciones.main']);
        this.$store.dispatch('navigation/breadcumbs/spliceBreadcumbs', [
            {
                position: 1,
                breadcumb: {
                    name: `Cotización ${this.detalleInfo.referencia}`,
                }
            }
        ]);
        await this.Action_get_rango_materiales(this.idCotizacion);
        await this.getSelects(['Action_get_select_monedas'])
        this.Action_get_select_measure_units();
        this.limpiarFiltro()
    },
    methods:{
        ...mapActions({
            Action_get_select_measure_units: 'selects/selects/Action_get_select_measure_units',
            Action_get_rango_materiales: 'cotizacion/detalleCotizacion/Action_get_rango_materiales',
            Action_enviar_cotizacion: 'cotizacion/detalleCotizacion/Action_enviar_cotizacion',
            Action_get_detalle_info: 'cotizacion/detalleCotizacion/Action_get_detalle_info',
            Action_get_materiales: 'cotizacion/detalleCotizacion/Action_get_materiales',
            Action_edit_material: 'cotizacion/detalleCotizacion/Action_edit_material',
            Action_clear_filters: 'cotizacion/detalleCotizacion/Action_clear_filters',
            getSelects: 'selects/selects/sync'
        }),
        ...mapMutations({
            set_filtros: 'cotizacion/detalleCotizacion/set_filtros',
        }),

        async listMateriales(){
            await this.Action_get_materiales({idCotizacion: this.idCotizacion});
        },
        async editarMaterial(_, idx){
            const payload = {
                id: this.materiales?.[idx]?.id ?? undefined,
                valor_unidad: this.materiales?.[idx]?.valor_unidad ?? undefined,
                garantia: this.materiales?.[idx]?.garantia ?? undefined,
                dias_entrega: this.materiales?.[idx]?.dias_entrega ?? undefined,
            }
            await this.Action_edit_material({idCotizacion: this.idCotizacion, payload})
        },
        async enviarCotizacion(payload){
            await this.Action_enviar_cotizacion({idCotizacion: this.idCotizacion, payload})
        },
        abrirModalFiltros(){
            this.$refs.abrirModalFiltros.toggle()
        },
        limpiarFiltro(){
            this.Action_clear_filters()
            this.listMateriales()
        },
        openEnviarCotizacion(){
            this.$refs.enviarCotizacion.toggle()
        }
    }
}
</script>

<style lang="scss" scoped>
.cotizacion-ver{
    height: calc(100vh - 195px);
    .br-50-6{
        border-radius: 50rem 6rem 6rem 50rem !important;
    }
    .contenedor-info{
        .img-logo-op{
            left: -3px;
            top: -1px;
        }
    }
}
</style>